import "animate.css"
import "./styles/global.scss"
import "./App.css"
import {Pool} from "./components/Pool";
import {Header} from "./components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const pools = [
    {
      days: '14',
      percent: '1.2%',
      limits: '50-100'
    },
    {
      days: '30',
      percent: '1.4%',
      limits: '70-150'
    },
    {
      days: '60',
      percent: '1.6%',
      limits: '100-175'
    },
    {
      days: '90',
      percent: '1.8%',
      limits: '150-200'
    },
  ]

  return (
    <main>
      <div className="bubbles">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div>
      <Header/>
      <div className='main-content-wrapper'>
        <p>
          We're excited to share that Mondero Finance is now in its initial phase! Your input and cooperation are crucial as we build our new platform. Thank you for your ongoing support. If you need any help, please contact us at <a
          href="mailto:support@mondero.finance">support@mondero.finance</a>.
        </p>
        <div className='main-content'>
          {pools.map((i, index) => (
            <Pool key={index} days={i.days} percents={i.percent} limits={i.limits} comingSoon={false}/>
          ))}
        </div>
      </div>
      <ToastContainer/>
    </main>
  );
}

export default App;
