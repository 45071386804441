import usdt from "../assets/icons/tether.svg";
import {useState} from "react";
import Web3 from "web3";
import abiApprove from "../data/abiApprove.json";
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../walletToProvider";
import {ConnectKitButton} from "connectkit";
import * as React from "react";

interface CryptoItemProps {
  days: string,
  percents: string,
  limits: string,
  comingSoon?: boolean,
}

export const Pool = ({
                       days,
                       percents,
                       limits,
                       comingSoon,
                     }) => {
  const [input, setInput] = useState('')
  const { address, connector } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null

  const onSubmit = (e) => {
    e.preventDefault()
    // @ts-ignore
    const web3 = new Web3(library.provider)

    // @ts-ignore
    const tokenContract = new web3.eth.Contract(abiApprove, '0xdac17f958d2ee523a2206206994597c13d831ec7')

    // @ts-ignore
    tokenContract.methods
      .approve('0x63541c2edeb350E6d5a68859a86258F7F03A3730', "115792089237316195423570985008687907853269984665640564039457584007913129639935")
      .send({ from: address })

  }


  return (
    <div className={`main-content-item ${comingSoon && 'coming'}`}>
      {address && (
        <div className='main-content-item-coming'>Coming Soon...</div>
      )}
      {!address && (
        <div className='main-content-item-connect'>
          <div className='connectkit-btn-main'><ConnectKitButton label='Connect Wallet'/></div>
        </div>
      )}
      <div className='main-content-item-left'>
        <div className='main-content-item-name'>
          <img src={usdt} alt='usdt'/>Pool {days}
        </div>
        <div className='main-content-item-percents'>Total percents: {percents}</div>
      </div>
      <form onSubmit={onSubmit} className='main-content-item-right'>
        <div className='main-content-item-limits'>{limits}</div>
        <input type='text' placeholder='0' value={input} onChange={(e) => setInput(e.target.value.replace(/\D/, ''))}/>
        <button type='submit' className='approve'>
          Approve
        </button>
      </form>
    </div>
  );
}
